/* //////////////////////////////////////////////////////////////////////////////////////////////////////////// half page styling */

.offer09 .offer__header .offer__wrap {
    height: 520px;
}

.offer09 .offer__header  .offer__person1 {
    width: 100%;
    left: 55%;
}

.offer09 .offer__tasks {
    padding: 0;
}

.offer09 .offer__tasks .section__wrap {
    flex-wrap: wrap;
}

.offer09 .half {
    /* border: 2px solid red; */
    width: 100%;
    padding: 40px 20px;
}

.offer09 .offer__expect {
    background-color: var(--whiteColor);
}

.offer09 .half .offer__subtitle {
    padding: 10px 0;
}

.offer09 .offer__subtitle-two {
    text-transform: uppercase;
    font-family: Graphik_Bold, sans-serif;
    text-align: left;
    padding: 10px 0;
}

.offer09 .offer__expect .offer__subtitle-two,
.offer09 .offer__expect h3,
.offer09 .offer__expect .feature {
    color: var(--blackColor);
}

.offer09 .offer__expect h3 {
    margin-top: 30px;
    font-size: 20px;
}

.offer09 .offer__expect .feature {
    border-color: var(--blueColor);
    font-weight: bold;
}

.offer09 .half.second-path {
    background-color: var(--blueColor);
    padding-bottom: 30px;
}

.offer09 .second-path .offer__subtitle,
.offer09 .second-path .offer__subtitle-two,
.offer09 .second-path .section__row-text,
.offer09 .second-path .feature {
    color: var(--whiteColor);
}

.offer09 .second-path .feature {
    border-color: var(--greenColor);
}

.offer09 .second-path .section__row-image {
    border-color: var(--greenColor);
    color: var(--greenColor);
}

.offer09 .second-path .offer__expect {
    background-color: var(--blueColor);
}



@media (min-width: 768px) {
    .offer09 .offer__header .offer__wrap {
        height: 400px;
    }

    .offer09 .offer__header  .offer__person1 {
        width: 660px;
        height: auto;
        left: 40%;
    }

    /* .offer08 .offer__tasks {
        padding: 0;
    } */
    
    .offer09 .offer__tasks {
        padding-top: 60px;
    }

    .offer09 .offer__tasks .section__wrap {
        flex-wrap: nowrap;
    }
    
    .offer09 .half {
        width: 50%;
        padding: 10px 20px 40px;
        overflow: hidden;
    }

    .offer09 .half:first-of-type {
        border: 3px solid var(--blueColor);
        border-right: none;
    }

    .offer09 .half:last-of-type {
        border: 3px solid transparent;
        border-left: none;
    }
    
    .offer09 .half.second-path {
        padding-bottom: 40px;
    }

    .offer09 .half-up {
        /* height: 630px; */
    }

    .offer09 .second-path .offer__subtitle,
.offer09 .second-path .offer__subtitle-two {
    text-align: right;
}
}

/* //////////////////////////////////////////////////////////////////////////////////////////////////////////// standard styling */

.offer09 .offer__header .offer__wrap {
    height: 540px;
}

.offer09 .offer__header  .offer__person1 {
    width: 100%;
    left: 61%;
}

@media (min-width: 768px) {
    .offer09 .offer__header .offer__wrap {
        height: 460px;
    }

    .offer09 .offer__header  .offer__person1 {
        width: 850px;
        height: auto;
        left: 38%;
    }
}