.b .offer__header .offer__wrap {
    height: 520px;
}

@media (min-width: 768px) {
    .b .offer__header .offer__wrap {
        height: 410px;
    }

    .b .offer__header  .offer__person1 {
        height: 500px;
        right: 100px;
    }
}