.banner {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index:1000;
    width: 90%;
    max-width: 1050px;
    color: var(--whiteColor);
    background-color: rgba(0,0,0,.9);
    border-radius: 5px;
    box-shadow: 0 0 20px 5px rgba(0,0,0,.5);
}

.banner__text {
    font-family: Graphik, sans-serif;
    font-size: 20px;
    margin: 30px auto;
}

.banner__title {
    text-transform: uppercase;
    font-family: Graphik_Bold, sans-serif;
    font-size: 36px;
}

.banner__link {
    color: var(--whiteColor);
    transition: .3s;
}

.banner__link:hover {
    text-decoration: none;
}

.banner__btn {
    font-family: Graphik_Bold, sans-serif;
    color: inherit;
    font-size: 13px;
    background-color: var(--greenColor);
    border: none;
    padding: 8px 16px;
    margin: 30px auto;
    text-transform: uppercase;
    border-radius: 5px;
    cursor: pointer;
    transition: .3s;
}

.banner__btn:hover {
    background-color: var(--whiteColor);
    color: var(--blackColor);
    background-image: none;
    box-shadow: 0 0 0 2px var(--whiteColor);
}

@media (min-width: 768px) {
    .banner__text {
        font-size: 24px;
        margin: 30px auto;
    }
    
    .banner__title {
        font-size: 48px;
    }
    
    .banner__btn {
        font-size: 24px;
        padding: 6px 30px;
    }
}