@font-face {
  font-family: 'Graphik_Regular';
  src: local('Graphik_Regular'), url(./fonts/Fonty_Graphik-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Graphik_Bold';
  src: local('Graphik_Bold'), url(./fonts/Fonty_Graphik-Bold.ttf) format('truetype');
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  overflow-x: hidden;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Graphik_Regular";
}

:root {
  --whiteColor: #fff;
  --blackColor: #141414;
  /* --greenColor: #00D700; */
  --greenColor: #a100ff;
  --blueColor: #00baff;
}

.green {
  color: var(--greenColor);
}
