.c .offer__header .offer__wrap {
    height: 570px;
}

@media (min-width: 768px) {
    .c .offer__header .offer__wrap {
        height: 410px;
    }

    .c .offer__header  .offer__person1 {
        height: 450px;
        right: 120px;
    }
}