.offer08 .offer__header .offer__wrap {
    height: 520px;
}

.offer08 .offer__header  .offer__person1 {
    width: 100%;
    left: 55%;
}

.offer08 .offer__tasks {
    padding: 0;
}

.offer08 .offer__tasks .section__wrap {
    flex-wrap: wrap;
}

.offer08 .half {
    /* border: 2px solid red; */
    width: 100%;
    padding: 40px 20px;
}

.offer08 .offer__expect {
    background-color: var(--whiteColor);
}

.offer08 .half .offer__subtitle {
    padding: 10px 0;
}

.offer08 .offer__subtitle-two {
    text-transform: uppercase;
    font-family: Graphik_Bold, sans-serif;
    text-align: left;
    padding: 10px 0;
}

.offer08 .offer__expect .offer__subtitle-two,
.offer08 .offer__expect .feature {
    color: var(--blackColor);
}

.offer08 .offer__expect .feature {
    border-color: var(--blueColor);
    font-weight: bold;
}

.offer08 .half.second-path {
    background-color: var(--blueColor);
    padding-bottom: 30px;
}

.offer08 .second-path .offer__subtitle,
.offer08 .second-path .offer__subtitle-two,
.offer08 .second-path .section__row-text,
.offer08 .second-path .feature {
    color: var(--whiteColor);
}

.offer08 .second-path .feature {
    border-color: var(--greenColor);
}

.offer08 .second-path .section__row-image {
    border-color: var(--greenColor);
    color: var(--greenColor);
}

.offer08 .second-path .offer__expect {
    background-color: var(--blueColor);
}



@media (min-width: 768px) {
    .offer08 .offer__header .offer__wrap {
        height: 400px;
    }

    .offer08 .offer__header  .offer__person1 {
        width: 660px;
        height: auto;
        left: 40%;
    }

    /* .offer08 .offer__tasks {
        padding: 0;
    } */
    
    .offer08 .offer__tasks {
        padding-top: 60px;
    }

    .offer08 .offer__tasks .section__wrap {
        flex-wrap: nowrap;
    }
    
    .offer08 .half {
        width: 50%;
        padding: 10px 20px 40px;
        overflow: hidden;
    }

    .offer08 .half:first-of-type {
        border: 3px solid var(--blueColor);
        border-right: none;
    }

    .offer08 .half:last-of-type {
        border: 3px solid transparent;
        border-left: none;
    }
    
    .offer08 .half.second-path {
        padding-bottom: 40px;
    }

    .offer08 .half-up {
        height: 560px;
    }

    .offer08 .second-path .offer__subtitle,
.offer08 .second-path .offer__subtitle-two {
    text-align: right;
}
}