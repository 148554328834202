.home {
    position: relative;
}

.home__header {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: var(--whiteColor);
    height: 80px;
    z-index: 10;
}

.home__image {
    position: absolute;
    right: 0;
    padding-right: 50px;
    height: 50%;
    max-width: 300px;
}

.main {
    position: relative;
    top: 80px;
    left: 0;
    margin: 0 auto;
    min-height: 50vh;
    display: flex;
    flex-direction: column;
}

.home__logo-wrap {
    width: 100%;
    height: calc(100vh - 80px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.home__logo {
    width: 50%;
    height: 1px;
    position: relative;
    /* width: 25%;      do it only at 768px +
    position: fixed; */
}

.home__logo img {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 75%;
    transform: translate(-50%, -50%);
}

.home__texts {
    text-transform: uppercase;
    position: absolute;
    left: -25%;
    transform: translateY(-50%);
    text-align: left;
    font-size: 48px;
    font-weight: bold;
    font-family: Graphik_Bold, sans-serif;
}
.home__logo p {
    line-height: .9;
}

.home__logo .small {
    font-size: 24px;
    text-align: left;
}

.home__offers {
    padding: 40px 0;
    /* background-image: linear-gradient(rgba(0,0,0,.8), rgba(0,0,0,.8)), url("./background1.jpg"); */
    background-size: cover;
    background-position: center;
}

.home__button {
    font-family: inherit;
    background-color: var(--greenColor);
    border: none;
    padding: 30px;
    margin: 10px auto;
    width: 90%;
    font-size: 20px;
    text-transform: uppercase;
    color: var(--whiteColor);
    font-weight: bold;
    letter-spacing: 1px;
    border-radius: 5px;
    cursor: pointer;
    transition: .3s;
    font-family: Graphik_Bold, sans-serif;
    background-image: linear-gradient(100deg, transparent, rgba(0,0,0,.4));
    box-shadow: inset 0 0 20px 1px rgba(0,0,0,.5);
}

.home__button:hover {
    background-color: var(--whiteColor);
    color: var(--blackColor);
    background-image: none;
    box-shadow: inset 0 0 0 2px var(--greenColor);
}

.footer {
    position: relative;
    top: 80px;
    min-height: 200px;
    padding: 30px;
    background-color: var(--whiteColor);
    color: var(--blackColor);
    text-align: left;
    line-height: 1;
    display: flex;
    flex-direction: column;
}

.footer > * {
    flex-basis: 50%;
}

.footer__texts {
    font-size: 24px;
    text-transform: uppercase;
    font-family: Graphik_Bold, sans-serif;
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
}

.footer__socials {
    display: flex;
    height: 50%;
    justify-content: space-between;
}

.home .footer__socials {
    margin-top: 30px;
}


.home .offer__contact {
    margin: 10px auto 20px;
    background-color: var(--whiteColor);

}

.footer .form {
    background-color: var(--whiteColor);
    /* box-shadow: 0 0 0 20px var(--blackColor); */
}

.footer .form label,
.footer .form a {
    color: var(--blackColor);
}

.footer__socials i {
    background-color: var(--blackColor);
    color: var(--whiteColor);
    width: 50px;
    height: 50px;
    border-radius: 50%;
    line-height: 50px;
    text-align: center;
    margin: 10px;
    transition: .5s;
}

.footer__socials a:hover i {
    background-color: var(--whiteColor);
    color: var(--blackColor);
    box-shadow: 0 0 0 1.5px var(--blackColor);
}

.home .firstLabel {
    margin-bottom: 0;
}

.home .extra {
    display: none;
}

.home .extra.visible {
    display: inline;
}

.home .extra-toggler {
    color: var(--blackColor);
    text-decoration: underline;
    cursor: pointer;
    margin: 0;
    text-align: right;
    display: inline-block;
    width: 120px;
    margin-left: calc(100% - 120px);
    padding-top: 0;
}

@media (min-width: 768px) {
    .main {
        flex-direction: row;
        min-height: calc(100vh - 150px);
    }

    .main > * {
        flex-basis: 50%;
    }

    .home__logo {
        width: 25%;
        position: fixed;
    }
    
    .home__logo p {
        font-size: 64px;
    }

    .home__logo .small {
        font-size: 32px;
    }
    
    .home__offers {
        padding: 40px 0;
        /* background-image: linear-gradient(rgba(0,0,0,.8), rgba(0,0,0,.8)), url("./background1.jpg"); */
        /* background-size: cover; */
        /* background-position: center; */
    }

    .offers__wrap {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        height: 100%;
    }

    .footer {
        padding: 50px;
    }

    .footer__texts {
        font-size: 36px;
    }

    .home .offer__contact {
        margin: 40px auto;
    }
    
    .footer__socials {
        justify-content: flex-end;
    }
}

@media (min-width: 1200px) {
    .home__texts {
        left: 0%;
    }
}