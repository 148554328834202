.offer05 .offer__header .offer__wrap {
    height: 670px;
}

.offer05 .offer__details {
    height: 590px;
}

.offer05 .offer__details .section__wrap {
    height: 560px;
}

.offer05 .details__sentence {
    font-size: 20px;
    text-align: left;
}

@media (min-width: 768px) {
    .offer05 .offer__header .offer__wrap {
        height: 500px;
    }

    .offer05 .offer__header  .offer__person1 {
        height: 500px;
        right: 40px;
    }

    .offer05 .offer__details {
        height: 400px;
    }
    
    .offer05 .offer__details .section__wrap {
        height: 400px;
    }
    
    .offer05 .details__sentence {
        width: 50%;
    }

    .offer05 .offer__expect .section__content {
        flex-basis: 70%;
    }

    .offer05 .offer__expect .offer__subtitle {
        padding: 0;
    }

    .offer05 .offer__expect .offer__subtitle.one {
        margin: 0;
    }
}