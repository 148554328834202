.offer06 .offer__header .offer__wrap {
    height: 610px;
}

.offer06 .offer__header  .offer__person1 {
    width: 80%;
    left: 35%;
}

@media (min-width: 768px) {
    .offer06 .offer__header .offer__wrap {
        height: 470px;
    }

    .offer06 .offer__header .offer__title {
        position: relative;
        z-index: 100;
    }

    .offer06 .offer__header  .offer__person1 {
        /* height: 400px; */
        /* right: 120px; */
        width: 550px;
        height: auto;
        left: 40%;
    }
}