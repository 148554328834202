@media (min-width: 768px) {
    .offer03 .offer__header .offer__wrap {
        height: 410px;
    }

    .offer03 .offer__header  .offer__person1 {
        height: 480px;
        right: 120px;
    }

    .offer03 .section .section__content {
        flex-basis: 65%;
    }

    .offer03 .section.offer__expect .section__content {
        flex-basis: 100%;
    }
}