.offer {
    background-color: #fff;
}

.offer > * {
    padding: 30px 20px;
}

.offer__apply {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 80px;
    background-color: var(--whiteColor);
    z-index: 20;
    padding: 0;
    transition: .3s;
}

.offer__apply .section__wrap {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 100%;
    color: black;
}

.offer__apply .offer__image {
    height: 40px;
    width: auto;
}

.offer__apply .offer__button {
    padding: 8px 16px;
}

.offer__applyA.invisible {
    top: -300px;
}

.offer__header {
    align-items: center;
    padding-top: 100px;
    margin-top: 40px;
    color: var(--whiteColor);
    min-height: 50vh;
    background-color: var(--blueColor);
    color: var(--whiteColor);
    margin: 40px auto 0;
    padding-bottom: 0;
}

.offer__titles h1 {
    font-family: Graphik_Bold, sans-serif;
    text-transform: uppercase;
    font-size: 36px;
}

.offer__header .offer__wrap {
    position: relative;
    height: 540px;
}

.offer__image {
    width: 80%;
    max-width: 400px;
}

.offer__header .offer__image {
    position: absolute;
    display: block;
    width: 200px;
    bottom: -45px;
    left: 30%;
    transform: translateX(-50%);
    z-index: 10;
}

.offer__header .offer__person1 {
    position: absolute;
    display: block;
    width: 55%;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 11;
}

.header__info {
    width: 100%;
    margin: 30px auto 0;
    position: relative;
    font-size: 20px;
}

.header__info::before {
    content: "";
    position: absolute;
    top: -15px;
    left: 50%;
    transform: translateX(-50%);
    width: 50%;
    height: 2px;
    background-color: var(--greenColor);
}

.header__info .row {
    margin: 0 auto;
    display: flex;
    padding: 10px 20px;
    width: 80%;
}

.header__info img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.offer__titles {
    line-height: 1.5;
}

.offer__button,
.form .offer__button {
    font-family: inherit;
    background-color: var(--greenColor);
    border: none;
    padding: 10px 20px;
    margin: 0 auto;
    text-transform: uppercase;
    color: var(--whiteColor);
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
    font-family: Graphik_Bold, sans-serif;
    transition: .3s;
}

.offer__button:hover,
.form .offer__button:hover {
    background-color: var(--whiteColor);
    color: var(--blackColor);
    background-image: none;
    box-shadow: 0 0 0 1.5px var(--blackColor);
}

/* ///////////////////////////////////////////////////////////// */

.section {
    color: var(--blackColor);
}

.section__wrap {
    display: flex;
    max-width: 1000px;
    margin: 0 auto;
}

.section .section__content {
    flex-basis: 100%;
}

.section .offer__subtitle {
    margin: 0 0 30px 0;
    text-align: left;
    font-family: Graphik_Bold, sans-serif;
    text-transform: uppercase;
    font-size: 36px;
}

.section__table {
    width: 100%;
}

.section__row {
    display: flex;
    justify-content: flex-start;
    padding: 15px 0;
    font-weight: bold;
}

.section__row-image {
    flex-basis: 50px;
    min-height: 60px;
    line-height: 60px;
    font-size: 40px;
    padding-right: 20px;
}

.section__row-text {
    flex-basis: 100%;
    display: block;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    text-align: left;
    font-size: 20px;
    padding: 0 10px;
    font-weight: bold;
}

.section__image-wrap {
    display: none;
}

/* ///////////////////////////////////////////////////////////// */

.offer__benefits {
    padding-top: 50px;
}

.offer__benefits .section__table {
    display: flex;
    flex-wrap: wrap;
}

.offer__benefits .section__image-wrap {
    flex-basis: 40%;
    
}

.offer__benefits .section__row-text {
    background-color: #eee;
    display: inline;
    padding: 5px 15px;
    margin: 5px;
    flex-basis: auto;
}

/* ///////////////////////////////////////////////////////////// */

.offer__details {
    color: var(--whiteColor);
    height: 460px;
    padding: 30px 20px 0;
    background-color: var(--blueColor);
}

.offer__details .section__wrap {
    width: 100%;
    height: 100%;
    position: relative;
}

.offer__person2 {
    position: absolute;
    bottom: 0;
    left: 55%;
    width: 60%;
    transform: translateX(-50%);
}

.offer__details .offer__subtitle {
    margin-bottom: 0;
    font-size: 36px;
    line-height: 1.1;
}

/* ///////////////////////////////////////////////////////////// */

.offer__tasks .section__row-image {
    color: var(--blueColor);
    border: 1.5px solid var(--blueColor);
    border-radius: 25px;
    padding: 0;
    min-width: 50px;
    font-size: 20px;
    min-height: 50px;
    max-height: 50px;
    line-height: 50px;
    margin-right: 10px;
}

.offer__tasks .section__row-text {
    justify-content: flex-start;
    text-align: left;
}

.offer__tasks .offer__subtitle {
    margin: 0;
    padding: 0;
}

/* ///////////////////////////////////////////////////////////// */

.offer__expect {
    background-color: var(--blueColor);
    color: var(--whiteColor);
}

.offer__expect .offer__subtitle {
    line-height: 1.1;
}

.offer__expect .must-have {
    text-align: left;
}

.offer__expect h3 {
    font-size: 24px;
    margin: 10px 5px;
    text-transform: uppercase;
    font-family: Graphik_Bold, sans-serif;
}

.offer__expect .feature {
    font-size: 20px;
    display: inline-block;
    padding: 5px 15px;
    margin: 5px;
    border: 3px solid var(--greenColor);
}

/* ///////////////////////////////////////////////////////////// */

.offer__about {
    padding: 0 20px;
}

.section__video {
    margin: 0 auto;
    height: auto;
}

/* ///////////////////////////////////////////////////////////// */

.offer__contact {
    color: var(--blackColor);
    background-color: var(--whiteColor);
}

.offer__contact .offer__subtitle {
    margin: 0;
    padding: 0;
}

.offer__contact a {
    color: var(--blackColor);
}

.form > * {
    display: block;
    width: 100%;
    max-width: 600px;
    padding: 8px 10px;
    margin: 30px auto;
}

.form input {
    background-color: var(--whiteColor);
    color: var(--blackColor);
    border: none;
    border-bottom: 1.5px solid gray;
    font-family: inherit;
    font-size: 16px;
}

.form select {
    background-color: var(--whiteColor);
    color: gray;
    font-family: inherit;
    font-size: inherit;
    border: 1.5px solid gray;
}

.offer__contact label {
    color: var(--blackColor);
    text-align: left;
    line-height: 1.5;
}

.offer__footer {
    background-color: var(--blueColor);
}

.offer__footer .offer__button:hover {
    box-shadow: 0 0 0 1.5px var(--blackColor);
}

.firstLabel {
    margin-bottom: 0;
}

.extra {
    display: none;
}

.extra.visible {
    display: inline;
}

.extra-toggler {
    text-decoration: underline;
    cursor: pointer;
    margin: 0;
    text-align: right;
    display: inline-block;
    width: 120px;
    margin-left: calc(100% - 120px);
    padding-top: 0;
}

@media (min-width: 768px) {
    .offer > * {
        padding: 60px;
    }

    .offer__titles h1 {
        font-size: 64px;
        letter-spacing: 1px;
    }

    .offer__titles h2 {
        font-size: 36px;
        letter-spacing: 1px;
    }

    .section .offer__subtitle {
        font-size: 48px;
        padding: 30px 0;
    }
    
    .offer__button {
        font-family: inherit;
        background-color: var(--greenColor);
        border: none;
        padding: 15px 40px;
        margin: 0 auto;
        text-transform: uppercase;
        color: var(--whiteColor);
        font-weight: bold;
        letter-spacing: 1px;
        border-radius: 5px;
        cursor: pointer;
        font-size: 24px;
    }

    .offer__apply{
        padding: 0;
        height: 90px;
    }

    .offer__apply .section__wrap {
        justify-content: space-between;
    }
    
    .offer__apply .offer__button {
        padding: 6px 30px;
    }

    .offer__apply .offer__button {
        font-family: Graphik_Bold, sans-serif;
    }

    /* //////////////////////////////////////////////////// */

    .offer__header {
        margin-top: 70px;
        height: auto;
        padding-bottom: 0;
    }

    .offer__header .offer__wrap {
        position: relative;
        height: 480px;
        max-width: 1000px;
        margin: 0 auto;
    }
    
    .offer__header .offer__image {
        width: 400px;
        bottom: -90px;
        left: 0;
        transform: none;
    }
    
    .offer__header .offer__person1 {
        right: 40px;
        left: auto;
        height: 720px;
        width: auto;
        transform: none;
        z-index: 11;
    }

.offer__title {
    text-align: left;
    padding: 0 40px;
    line-height: 1.1;
}

    .header__info {
        margin: 60px 0 0 0;
        width: 100%;
    }
    
    .header__info::before {
        top: -30px;
        left: 45px;
        transform: none;
        width: 25%;
    }
    
    .header__info .row {
        padding: 5px 40px;
        font-size: 20px;
        width: 100%;
    }
    
    .header__info img {
        width: 40px;
        height: 40px;
        margin-right: 30px;
    }
    
    .offer__titles {
        line-height: 1.5;
    }

    /* //////////////////////////////////////////////////// */

    .offer__tasks .offer__subtitle {
        text-align: left;
    }

    .section .section__content {
        flex-basis: 60%;
    }

    .offer__contact .offer__subtitle {
        padding-left: 50px;
    }

    .section__row-text {
        display: flex;
        width: auto;
        align-items: center;
        padding: 0 10px;
    }
    
    .section__image-wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-basis: 50%;
    }

    .section__image {
        width:300px;
    }

    /* //////////////////////////////////////////////////// */

.offer__details {
    padding: 0;
}

.offer__details .offer__subtitles {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

    .offer__details .offer__image {
        width: 340px;
        bottom: -137px;
        left: auto;
        right: 0;
        transform: none;
    }
    
    .offer__details .offer__subtitles {
        padding-left: 10%;
    }
    
    .offer__details .offer__subtitle {
        margin-bottom: 0;
        padding: 0;
        font-size: 64px;
    }

    .offer__details {
        height: 400px;
        padding-bottom: 0;
    }
    
    .offer__person2 {
        position: absolute;
        bottom: -10px;
        left: 90%;
        width: 30%;
        transform: translateX(-100%);
    }

    /* //////////////////////////////////////////////////// */

.offer__tasks .offer__subtitles {
    margin: 30px 0 40px 0;
}

.offer__tasks .offer__subtitle {
    margin: 0;
    padding: 0;
}

.offer__tasks .section__row-text {
    justify-content: flex-start;
}

    /* //////////////////////////////////////////////////// */
    
  /* EXPECTATIONS */

    /* //////////////////////////////////////////////////// */

.offer__about {
    padding: 0 60px;
}

    .offer__about .section__video {
        width: 100%;
        height: 563px;
    }

    .section__video iframe {
        width: 100%;
        height: 563px;
    }

    /* //////////////////////////////////////////////////// */
    
    .offer__contact {
        color: var(--blackColor);
        background-color: var(--whiteColor);
    }

    .offer__contact .offer__subtitle {
        margin: 0;
        padding: 0;
    }
    
    .offer__contact a {
        color: var(--blackColor);
    }
    
    form > * {
        display: block;
        width: 100%;
        padding: 8px 10px;
        margin: 30px 0;
    }
    
    form input {
        background-color: var(--whiteColor);
        color: var(--blackColor);
        border: none;
        border-bottom: 1.5px solid var(--blackColor);
        font-family: inherit;
        font-size: 16px;
    }
    
    .offer__contact label {
        color: var(--blackColor);
        text-align: left;
        line-height: 1.5;
    }

    form .offer__button {
        font-size: 24px;
        padding: 15px 40px;
        font-family: Graphik_Bold, sans-serif;
    }

    .offer__footer {
        background-color: var(--blueColor);
    }

    .offer__footer .offer__button {
        font-family: Graphik_Bold, sans-serif;
    }

    .extra-toggler {
        margin-left: 480px;
    }
}