.offer04 .offer__header {
    overflow: hidden;
}

.offer04 .offer__header .offer__wrap {
    height: 660px;
}

.offer04 .offer__header  .offer__person1 {
    width: auto;
    height: 270px;
    left: 60%;
}

@media (min-width: 768px) {
    .offer04 .offer__header .offer__wrap {
        height: 470px;
    }

    /* .offer06 .offer__header .offer__title {
        position: relative;
        z-index: 100;
    } */

    .offer04 .offer__header  .offer__person1 {
        height: 500px;
        /* right: 120px; */
        /* width: 550px;
        height: auto; */
        left: 44%;
    }
}