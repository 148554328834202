.offer07 .offer__header .offer__wrap {
    height: 580px;
}

@media (min-width: 768px) {
    .offer07 .offer__header .offer__wrap {
        height: 470px;
    }

    .offer07 .offer__header  .offer__person1 {
        height: 550px;
        right: 100px;
    }
}